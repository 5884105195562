import React, { useState, useEffect } from 'react';

function CountdownTimer() {
  const initialCountdownValue = 60; // Set the initial value
  const [countdown, setCountdown] = useState(initialCountdownValue);

  useEffect(() => {
    // Only set up a timer if the countdown is greater than 0
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 100);
    } else {
      // Reset the countdown when it reaches 0
      setCountdown(initialCountdownValue);
    }

    // Clear the timer
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div>
      <p>{countdown}</p>
    </div>
  );
}

export default CountdownTimer;
