import React, { useState, useEffect } from 'react';

function CountdownTimerHours() {
  const initialCountdownValue = 24; // Set the initial countdown value
  const [countdown, setCountdown] = useState(initialCountdownValue);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000); // Change to 3600000 if each unit represents an hour
    } else {
      // Reset the countdown when it reaches 0
      setCountdown(initialCountdownValue);
    }

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <div>
      <p>{countdown}</p>
    </div>
  );
}

export default CountdownTimerHours;

