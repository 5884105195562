import './App.css';
import CountdownTimer from './CountdownTimer';
import CountdownTimerHours from './CountdownTimerHours';

function App() {
  return (

    <div>
      <div className="relative min-h-screen z-30">
        {/* Overlay Div */}
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-[#1E1E1E] bg-opacity-90 z-20"></div>
        {/* This is the 1st section with the video and overlay */}
        <video autoPlay loop muted className="absolute top-0 left-0 w-full h-full object-cover fit-content">
          <source src="media/videos/bg-static-noise_1s.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="z-40 absolute top-0 left-0 w-full h-full object-cover font-anton">
          <div className="flex flex-col mt-20">
            <h1 className="text-4xl sm:text-6xl font-bold text-center uppercase font-inter animate-jump animate-duration-[600ms] animate-delay-[2000ms] animate-ease-in">Boost Co</h1>
            <p className="text-5xl sm:text-7xl font-bold text-center uppercase mt-20 sm:mt-20 animate-jump-in animate-once animate-duration-1000 animate-delay-200">Downloading</p>
            <p className="text-6xl sm:text-8xl font-bold text-center uppercase mt-3 sm:mt-6 tracking-widest animate-jump-in animate-once animate-duration-1000 animate-delay-1000">More Fire</p>
            <p className="text-6xl sm:text-8xl font-bold text-center uppercase mt-3 sm:mt-6 tracking-widest animate-jump-in animate-once animate-duration-1000 animate-delay-1000">power</p>
            <img src="media/gifs/loading-bar.gif" alt="red-loading-bar" className="w-64 sm:max-w-sm mx-auto mt-10" />
          </div>
          <div className='uppercase font-light text-center font-LeagueSpartan mt-10 sm:mt-10 text-sm sm:text-xl font-roboto-condensed'>
            <p>Give your business a boost with developers, business</p>
            <p>development, marketing, admin and strategic support</p>
          </div>
          <div className='font-light text-center font-LeagueSpartan mt-4 sm:mt-10 sm:text-2xl font-inter'>
            <p classname="">
              <a className="transform hover:text-[#FF5757]" href="mailto:boostco@explorage.com">anna@boostco.io</a>
            </p>
          </div>
        </div>
      </div>

      {/* This is the 2nd section */}
      <div className="relative bg-starBackground bg-black -z-20">
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-[#1E1E1E] bg-opacity-90 -z-10"></div>
        <div className="z-40 w-full h-full object-cover font-anton">
          <div className='flex justify between'>
            <img src="media/images/tear-big.png" alt="white tear" className="w-[140px] sm:w-[290px] lg:w-[430px] xl:w-[500px] sm:h-auto transform z-40 mt-4" />
            <div className="bg-[#FF5757] h-10 sm:h-20 text-4xl pr-40 lg:pr-20 w-[2000px] rotate-15 z-20 overflow-hidden items-center justify-end transform translate-x-10 hidden lg:flex text-black">
              MAKE YOUR LIFE EASIER
            </div>
            <div className="bg-[#FF5757] h-8 text-xs w-[2000px] rotate-15 z-20 items-center justify-end transform translate-x-4 -translate-y-2 flex pr-5 md:pr-32 lg:hidden text-black overflow-hidden">
              MAKE YOUR LIFE EASIER
            </div>
          </div>
          {/* flexbox for get ready and boost */}
          <div className='flex flex-col max-w-7xl'>
            <div className='flex justify-start text-4xl sm:text-8xl items-center sm:mt-20 ml-6 sm:ml-16'>
              <img src="media/gifs/gsmall.gif" alt="red-loading-bar" className="h-10 sm:h-24 lg:h-32 w-10 sm:w-24 lg:w-32" />
              <img src="media/gifs/esmall.gif" alt="red-loading-bar" className="h-10 sm:h-24 lg:h-32 w-10 sm:w-24 lg:w-32" />
              <img src="media/gifs/tsmall.gif" alt="red-loading-bar" className="h-10 sm:h-24 lg:h-32 w-10 sm:w-24 lg:w-32" />
              <p className='uppercase ml-2 sm:ml-10 text-white'>ready</p>
            </div>
            <div>
              <p className='uppercase ml-6 sm:ml-20 font-inter text-3xl sm:text-[60px] lg:text-[100px] xl:text-[140px]  font-extrabold border sm:border-[10px] border-[#E4DFD8] w-fit mt-2 sm:mt-10 pl-2 pb-5 pr-4 sm:pb-20 sm:pt-12 sm:pr-16 text-white'>for a boost!</p>
            </div>
          </div>

          {/* who are we text & team image */}
          <div className='flex flex-row sm:flex-row justify-between mt-4 md:mt-10 text-base sm:text-4xl font-roboto items-center'>
            <div className='ml-4 sm:ml-10 '>
              <h2 className='text-white'>WHO ARE WE</h2>
              <p className=' text-xs md:text-lg  mt-2 mb-4 md:mt-6'>BOOST Co is an award winning team of awesome start up
                disrupters. They thrive on deadlines, challenges and most of all
                achieving results. BOOST Co is the bootstrapping arm of RDC9
                Ltd, contracting its top quality team in tandem with developing
                and operating market leading consumer led technology.
                At BOOST Co we believe in making life easier for people. In doing
                so we deliver human led solutions with commercial bite. </p>
            </div>
            <img src="media/images/team-gray.png" alt="our staff" className="w-44  sm:w-[200px] lg:w-[500px] xl:w-[600px] h-auto sm:mr-10 -mt-20 sm:mt-0" />
          </div>

          {/* white border with iframe */}
          <div className='border border-white width-full h-fit mx-2 p-4 sm:mx-10 font-league-spartan text-sm lg:text-xl'>
            <h3 className="mb-6">WHAT CAN WE DO FOR YOU ?</h3>
            <h4>Tech Firm ?</h4>
            <p>Need developer resource? Our back end and front end power couple take pleasure in digesting your plans and transforming them into reality. Working alongside your existing  team, or delivering on stand alone projects, Thomas and Mark can be hired individually or together. They blend quality technical talent, strong interpersonal skills and life experience, to achieve outstanding results.
              Need sales or operational support? Look no further; Jaqui and Danielle are incredible at delivering effective business and customer support services using and adapting to new software rapidly. They also speak basic tech and will make sure your needs are interpreted and delivered efficiently.
            </p>

            <h4 className="mt-6">Consultancy?</h4>
            <p>Business development services, admin support, social media, web development, strategic planning; we have smart people who can think on their feet and learn quickly meaning we can adapt to your specific demands and those of your business.  </p>

            <h4 className="mt-6">Service Business?</h4>
            <p> If there’s one thing the BOOST Co team have in common, it’s that they are all human centred and personable. They fundamentally understand the importance of customer success, and business relationships and work seamlessly to achieve what that means for you and your business; with a generous measure of commercial nowse and business appreciation included. </p>
          </div>

          {/* red boxes */}
          <h3 className="mt-6 sm:mt-20 text-center font-bebas-neue text-xl sm:text-2xl mb-2 sm:mb-10">Running Out Of Time ?</h3>
          <div className='flex flex-row justify-center gap-6 sm:gap-16  text-center sm:text-3xl font-inter extra-bold px-4'>
            <div className="bg-[#BA3731] rounded-md sm:h-44 w-24 sm:w-44 pt-3">
              <p className='text-4xl sm:text-8xl animate-ping animate-infinite animate-duration-[2000ms]'>?</p>
              <div className="border-dashed border-t border-white mx-4 pb-2"></div>
              <p>DAYS</p>
            </div>
            <div className="bg-[#BA3731] rounded-md sm:h-44 w-24 sm:w-44 pt-3">
              <div className='text-4xl sm:text-8xl'>
                <CountdownTimerHours />
              </div>
              <div className="border-dashed border-t border-white mx-4 pb-2"></div>
              <p>HOURS</p>
            </div>
            <div className="bg-[#BA3731] rounded-md sm:h-44 w-24 sm:w-44 pt-3">
              <div className='text-4xl sm:text-8xl'>
                <CountdownTimer />
              </div>
              <div className="border-dashed border-t border-white mx-4 pb-2"></div>
              <p>MINUTES</p>
            </div>
          </div>

          {/*1st  red cross */}
          <div className='relative text-black text-3xl mt-4 md:mt-8 mb-52 sm:mb-[400px]'>
            <img className="absolute -left-10 top-[60px] sm:top-[200px] Z-50 w-[300px] sm:w-[500px]" src="media/images/crack.png" alt="white tear" />

            <div className="absolute w-[110vw] h-8 sm:h-16 text-sm sm:text-base md:text-2xl bg-[#FF5757] rotate-10 origin-top-left pt-2 sm:pt-4 Z-50" style={{ left: '-5vw' }}>
              <div className="text-center flex justify-center gap sm:gap-72">
                <p className='pr-2  sm:flex '>TALK TO US</p>
                <p className='pl-40 sm:pl-10 lg:pl-40'><a className="hover:text-[#FF5757]" href="mailto:boostco@explorage.com">ANNA@BOOSTCO.IO</a></p>
              </div>
            </div>

            <div className="absolute w-[110vw] h-8 sm:h-16 text-sm sm:text-base md:text-2xl bg-[#FF5757] -rotate-10 origin-top-right transform -translate-y-3 pt-2 sm:pt-4 Z-10 overflow-hidden" style={{ left: '-5vw' }}>
              <p className="text-center">WHO DOES YOUR BUSINESS NEED ?</p>
            </div>
          </div>

          {/* staff images and bio */}
          <div className='grid grid-cols-1 space-y-6 relative text-xs font-inter max-w-2xl mx-auto'>
            <img className="absolute -right-[630px] top-[200px] Z-10 rotate-180" src="media/images/tear-big.png" alt="white tear" />
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-10 px-4">
              <img className="border border-white h-40 w-32" src="media/images/thomas1.webp" alt="developer 1"></img>
              <ul>
                <li>
                  <p className="mb-2"><strong>BACK END DEVELOPER - Thomas</strong></p>
                </li>
                <li>
                  <p><strong>Superpower: Speed</strong></p>
                </li>
                <li>
                  <p>Confident with PHP/Laravel Framework (MVC)</p>
                </li>
                <li>
                  <p>Confident with HTML/CSS/Tailwind CSS. </p>
                </li>
                <li>
                  <p>Knowledge of Python, JS, React REST API’s including RESTful </p>
                </li>
                <li>
                  <p>JSON APIs OOP/Design principals. MySQL, Docker, LAMP Stack</p>
                </li>
                <li>
                  <p>Git/Github, CI/CD. Use of GCP/AWS products.</p>
                </li>
                <li>
                  <p>Linux user/VSCode. Familiar with Agile, and tools like Trello and Linear, Obsidian, Semrush, GA4, Hotjar </p>
                </li>
                <li className='mb-2'>
                  <p>AI tools. </p>
                </li>
                <li>
                  <p>Kingston University: First Class BSc(hons) Computer Science</p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-10 px-4 pt-4">
              <img className="border border-white h-36 w-32" src="media/images/mark2.webp" alt="developer 1"></img>
              <ul className=''>
                <li>
                  <p className="mb-2"><strong>FRONT END DEVELOPER - Mark</strong></p>
                </li>
                <li>
                  <p><strong>Superpower: Commercial Acumen</strong></p>
                </li>
                <li>
                  <p>Html 5, Css, basic JavaScript, basic Python</p>
                </li>
                <li>
                  <p>Bootstrap, Tailwind, AI tools</p>
                </li>
                <li>
                  <p>Agile skills - Trello, Linear</p>
                </li>
                <li>
                  <p>Design/UX/UI - for wire framing, high fidelity designs and general design</p>
                </li>
                <li>
                  <p>Figma, Gimp</p>
                </li>
                <li>
                  <p>SEO guru, Semrush, GA4, Hotjar</p>
                </li>
                <li>
                  <p>Student/Intern Mentor</p>
                </li>
                <li className='mb-2'>
                  <p>10yrs + Medical Sales, Company Director</p>
                </li>
                <li>
                  <p>Code Institute: Diploma in Full Stack Development Software</p>
                </li>
                <li>
                  <p>Prifysgol Bangor Undergrad: BEng Applied Software Engineering</p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-10 px-4 pt-4">
              <img className="border border-white h-40 w-32" src="media/images/jaqui1.webp" alt="developer 1"></img>
              <ul className=''>
                <li>
                  <p className="mb-2"><strong>BUSINESS DEVELOPMENT / MARKETING - Jaqui</strong></p>
                </li>
                <li>
                  <p><strong>Superpower: Effectiveness</strong></p>
                </li>
                <li>
                  <p>Hospitality and event management experience. Customer success.</p>
                </li>
                <li>
                  <p>Hupspot, Salesforce, Storman CRM</p>
                </li>
                <li>
                  <p>ClickUp, Trello, GoogleWorkspace, MS Office Suite, GIMP.</p>
                </li>
                <li>
                  <p>Canva, Pro Create, Tidio, Moderate Google Analytics (GA4) , Moderate Semrush, Hotjar</p>
                </li>
                <li>
                  <p>Meta, Hootsuite, LinkedIn Sales Nav, TikTok, iMovie, Capcut, OpenAI (GPT4), Bard.</p>
                </li>
                <li>
                  <p>Invoicing and reconciliation, cash recc, debt chasing, call handling.</p>
                </li>
                <li>
                  <p>Revenue management and forecasting. </p>
                </li>
                <li className='mb-2'>
                  <p>Fluent in Spanish. Girl Guiding Leadership Qualification. Former travelling musician.</p>
                </li>
                <li>
                  <p>Open University Undergrad: BA Business Management</p>
                </li>
                <li>
                  <p>NVQL3 Hospitality Leadership and Supervision</p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-10 px-4 pt-4">
              <img className="border border-white h-40 w-32" src="media/images/danielle1.webp" alt="developer 1"></img>
              <ul className=''>
                <li>
                  <p className="mb-2"><strong>SALES / ADMINISTRATION - Danielle</strong></p>
                </li>
                <li>
                  <p><strong>Super power: Diligence</strong></p>
                </li>
                <li>
                  <p>Customer facing sales. Client management. Call handling.</p>
                </li>
                <li>
                  <p>Trello, Google Workspace, MS Office Suite.</p>
                </li>
                <li>
                  <p>Canva, Tidio, Meta, Hootsuite, TikTok, OpenAI (GA4).</p>
                </li>
                <li>
                  <p>Invoicing and reconciliation.</p>
                </li>
                <li className='mb-2'>
                  <p>Former cabin crew and airport security officer.</p>
                </li>
                <li>
                  <p>Keele University: BA Psychology and Human Biology</p>
                </li>
              </ul>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-10 px-4 pt-4">
              <img className="border border-white h-32 w-32" src="media/images/anna.webp" alt="developer 1"></img>
              <ul className=''>
                <li>
                  <p className="mb-2"><strong>FOUNDER / CEO - Anna</strong></p>
                </li>
                <li>
                  <p><strong>Super power: Lateral Thinking</strong></p>
                </li>
                <li>
                  <p>Business planning, OKR setting, investment raising. Public speaking, Mentoring. Sales. .</p>
                </li>
                <li>
                  <p>Project management. Marketing strategy. Cashflow forecasting and financial management.</p>
                </li>
                <li>
                  <p>Business development. Moderate product development. </p>
                </li>
                <li>
                  <p>Strategic planning. Executive reporting. Team building and management.</p>
                </li>
                <li>
                  <p>Feasibility studies. Prospecting. Chair of boards. Lecturing.</p>
                </li>
                <li>
                  <p>Property development and management.</p>
                </li>
                <li>
                  <p>Journalism and news reading. Customer facing sales. Hospitality.</p>
                </li>
                <li>
                  <p>Moderate Google Analytics (GA4), Semrush, Clickup, Hupsot, Trello, Google Workspace, Hotjar.</p>
                </li>
                <li>
                  <p>Microsoft Office, Canva, Tidio, Meta, Hootsuite, iMovie, Clickchamp, Obsidian.</p>
                </li>
                <li className='mb-2'>
                  <p>Basic filming.</p>
                </li>
                <li>
                  <p>Prifysgol Bangor: ILM Level 5 Leadership</p>
                </li>
                <li>
                  <p>MRICS - Member of Royal Institute of Chartered Surveyors </p>
                </li>
                <li>
                  <p>BSc (hons) Building Surveying</p>
                </li>
              </ul>
            </div>

          </div>

          <div className='h-[200px] sm:h-[300px] lg:h-[500px] text-black text-3xl mt-4 md:mt-8'>
            <div className="absolute w-[110vw] h-8 sm:h-16 text-sm sm:text-base md:text-2xl bg-[#FF5757] rotate-10 origin-top-left pt-2 sm:pt-4 Z-10 overflow-hidden" style={{ left: '-5vw' }}>
              <div className="text-center flex justify-center gap sm:gap-72">
                <p className='pr-4  sm:flex '>WHAT YOU NEED</p>
                <p className='pl-40 sm:pl-10 lg:pl-40'>WHEN YOU NEED IT</p>
              </div>
            </div>
            <div className="absolute w-[110vw] h-8 sm:h-16 text-sm sm:text-base md:text-2xl bg-[#FF5757] -rotate-10 origin-top-right transform -translate-y-3 pt-2 sm:pt-4 Z-10 overflow-hidden" style={{ left: '-5vw' }}>
              <p className="text-center">BUSINESS SUPPORT MADE SIMPLE</p>
            </div>
          </div>
        </div>
      </div>

      <footer className="py-10 bg-black opacity-90 text-center z-50 font-bebas-neue text-2xl">
        <h2 className="text-4xl font-bold">BoostCo</h2>
        <address className="mt-2 no-italic">
          <strong>Address:</strong>
          <p>RDC9 Ltd,</p>
          <p>M-SParc,</p>
          <p>Gaerwen,</p>
          <p>LL60 6AG</p>
        </address>
        <p className="mt-2">
          <strong>Email : </strong>
          <a href="mailto:anna@boostco.io" className="text-[#FF5757] hover:text-red-700"> anna@boostco.io
          </a>
        </p>
        <p className="mt-2">
          <strong>Phone : </strong>
          <a href="tel:+4407527546134" className="text-[#FF5757] hover:text-red-700 cursor-pointer z-40"> +44 07527 546134
          </a>
        </p>
        <p> &copy; BoostCO. 2023</p>
        <p className="text-start pl-10">Built by: Mark - Front-end developer</p>
      </footer>
    </div>

  );
}

export default App;
